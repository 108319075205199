@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Montserrat');

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(232, 232, 232);
  border-radius: 5px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: theme("colors.kupabluelight");
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: theme("colors.kupablue");
}
* {
  font-family: Montserrat;
 }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: theme("colors.backgroundgray");
  overflow-x: hidden;
}

.vertical-shadow {
  width: 99%;
  box-shadow: 0 0 2px 0 #000000;
}

/* https://tailwindcss.com/docs/functions-and-directives */
h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

h1,
h2,
h3,
h4,
h5,
h6,

p {
  @apply text-kupablack dark:text-kupawhite;
}

a {
  @apply text-kupablue hover:text-kupabluedark font-bold;
}

/* Text Selection Color */
::-moz-selection {
  /* Code for Firefox */
  color: theme("colors.kupawhite");
  background-color: theme("colors.kupablue");
}

::selection {
  color: theme("colors.kupawhite");
  background-color: theme("colors.kupablue");
}

/* * {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
 */
.excelButton {
  @apply text-center text-kupawhite bg-kupablue p-2 font-medium ease-in duration-200 transition-colors rounded-full;
}

/* Zoom effect */
.zoom {
  transition: 0.3s;
}

.zoom:hover {
  transform: scale(1.05);
}

/* Float */
.float {
  animation: float 5s;
  animation-iteration-count: infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-10px);
  }

  100% {
    transform: translatey(0px);
  }
}

/* Loader */
.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid theme("colors.kupablue");
  border-right: 3px solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.pagination-link {
  background-color: rgb(0 174 211 / 8%);
  color: #000000;
  gap: 0.5rem; 
  padding: 0.25rem 0.75rem; 
  border-radius: 5px;
  font-size: 15px;
  border: 2px solid transparent;
}
.pagination-link:hover {
  background-color: white;
  color: #080808;
  border: 1px solid rgb(0, 174, 211);
  
}
.pagination-link-active {
  font-weight: bold; 
  gap: 0.5rem; 
  padding: 0.25rem 0.75rem; 
  background-color: rgb(0, 174, 211);
  color: #feffff;
  font-weight: bold;
  
 
}
.pagination-link-active:hover {
  background-color: rgb(0, 174, 211);
  color: #feffff;
  cursor: pointer;
  
}

.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #1FB264;
  position: relative;
}
.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.drag-text {
  text-align: center;
  padding: 60px 0;

}

.drag-text h3 {
  font-weight: 50;
  text-transform: uppercase;
  color: #15824B;
}

@keyframes shakeEasterEgg {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}



